<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";

const token = JSON.parse(localStorage.getItem("user")).token;

const types = [
  {
    id: 1,
    name: "Rezervasyon Raporu",
    fields: ["agency", "hotel"],
  },
  {
    id: 2,
    name: "Gelir Raporu",
    fields: [],
  },
  {
    id: 3,
    name: "Gider Raporu",
    fields: [],
  },
  {
    id: 4,
    name: "Hesap & Kasa Raporu",
    fields: ["banks"],
  },
  {
    id: 5,
    name: "Personel Performans Raporu",
    fields: ["user"],
  },
  {
    id: 6,
    name: "Personel Raporu",
    fields: ["user"],
  },
  {
    id: 7,
    name: "Tur Raporu",
    fields: ["agency"],
  },
];

/**
 * Reports Index Page
 */
export default {
  components: { Layout, PageHeader},
  data() {
    return {
      title: "Rapor Yönetimi",
      items: [
        {
          text: "Reservation Track System (RTS)",
        },
        {
          text: "Rapor Yönetimi",
          active: true,
        },
      ],
      filters: [],
      reportTypes: types,
      type: "",
      filterLoading: false,
    };
  },
  methods: {
    addFields: function (endpoint) {
      const options = [];
      axios
        .get(`${process.env.VUE_APP_BASEURL}/${endpoint}/getAll`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          response.data.data.forEach((item) => {
            options.push({
              value: item.id,
              text: item.name ?? item.brand_name ?? item.company_name ?? item.bank,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.filters.push({
            label: endpoint,
            name: endpoint,
            options: options,
          });
          // disable filter loading
          this.filterLoading = false;
        });
    },
  },
  watch: {
    type: function (val) {
      // remove fields
      this.filters = [];

      // get fields for selected type
      const fields = types.find((type) => type.id === parseInt(val)).fields;
      if (fields && fields.length > 0) {
        fields.map((field) => {
          this.addFields(field);
        });
        this.filterLoading = true;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title mb-0">Rapor Oluşturucu</h4>
          </div>
          <div class="card-body">
            <form action="/reports/result">
              <input type="hidden" name="type" v-model="type">
              <div class="row align-items-center">
                <div class="col-12 mb-3">
                  <label class="form-label">Rapor Kategorisi</label>
                  <select
                    class="form-control"
                    name="type"
                    v-model="type"
                    :disabled="filterLoading"
                  >
                    <option value="">Seçiniz</option>
                    <option
                      v-for="type in reportTypes"
                      :key="type.id"
                      :value="type.id"
                    >
                      {{ type.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Başlangıç Tarihi</label>
                  <input type="date" class="form-control" name="start_date" />
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Bitiş Tarihi</label>
                  <input type="date" class="form-control" name="end_date" />
                </div>
                <div class="col-md-12">
                  <div
                    class="d-flex justify-content-center mb-3"
                    v-if="filterLoading"
                  >
                    <div class="spinner-border" role="status"></div>
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div v-else>
                    <h5 class="card-title" v-if="filters.length > 0">
                      Filtreler
                    </h5>
                    <div class="row" id="filterRow">
                      <div class="col-md-6 mb-3" v-for="(filter, index) in filters" :key="index">
                        <label class="form-label">
                          {{ $t("reports.filters." + filter.label) }}
                        </label>

                        <select class="form-control" :name="filter.name">
                          <option value="">Seçiniz</option>
                          <option
                            v-for="option in filter.options"
                            :key="option.value"
                            :value="option.value"
                          >
                            {{ option.text }}
                          </option>
                        </select> 

                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <button
                    class="btn btn-primary btn-block"
                    type="submit"
                    :disabled="filterLoading"
                  >
                    Rapor Oluştur
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
